@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap');

body {
  font-family: 'Open Sans', 'Lato', sans-serif;
  margin: 0;
  background-image: url('background.png');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
