.form-control {
  display: flex;
  justify-content: center;
}

.form-control input {
  font: inherit;
  padding: 0.5rem;
  border-radius: 5px;
  border: 2px solid #ffb703;
  width: 30rem;
  padding-left: 40px;
  background: url('../../search_icon.svg') no-repeat left;
  background-size: 25px;
  background-color: white;
  background-position-x: 0.6rem;
}

.form-control input:focus {
  outline: none;
  background-color: white;
}

.form-control div {
  display: flex;
  padding-top: 1rem;
  justify-content: center;
}
