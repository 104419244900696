.button {
  font: inherit;
  max-width: 8rem;
  width: 100%;
  border: 2px solid white;
  background: #ffb703;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.button:hover,
.button:active {
  background: #741188;
  border-color: #ffb703;
}

.button:focus {
  outline: none;
}

button:disabled,
button:focus:disabled,
button:hover:disabled,
button:active:disabled {
  background: #ccc;
  border-color: #ccc;
  color: #666666;
  cursor: not-allowed;
}
