.header {
  height: 6rem;
  background-color: #ffb703;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding: 0 5%;
}

.client-logo {
  height: 45px;
  pointer-events: none;
}

.wallet-button {
  position: absolute;
  top: 15;
  right: 3rem;
}

.header ul {
  list-style: none;
  display: flex;
}

.header li {
  margin: 0 2.2rem;
}

.header a {
  text-decoration: none;
  color: #faf6eb;
  font-weight: bold;
}

.header a:hover {
  color: #c291e2;
}

.header button:hover {
  background-color: #c291e2;
  color: #1a36d6;
}

.inactive a {
  text-decoration: none;
  color: #faf6eb;
}

.active a {
  text-decoration: none;
  color: white;
}
